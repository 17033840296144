/**
 * 利用環境種別
 */
export const DeviceKind = {
  NONE: '',
  /** WEB版 */
  WEB: 'web',
  /** アプリ版 */
  APP: 'app',
} as const
export type DeviceKindType = typeof DeviceKind[keyof typeof DeviceKind]

export const DeviceKindList: {
  name: string
  kind: DeviceKindType
}[] = [
  { name: 'WEB版', kind: DeviceKind.WEB },
  { name: 'アプリ版', kind: DeviceKind.APP },
]
