import styled from '@emotion/styled'
import React from 'react'
import BreakPoints from '../../styles/breakPoints'
import OfficialCommonBtn from '../atoms/officialCommonBtn'
import ContactFlow from '../molecules/contactFlow'
import OfficialLowHeader from './officialLowHeader'
import { IContactSupport } from '../../types/Contact'
import useKindName from '../../hooks/useKindName'
import { SupportKindList } from '../../types/SupportKind'
import { DeviceKindList } from '../../types/DeviceKind'

const Wrapper = styled.section`
  width: 100%;

  .p-section-inner {
    max-width: 620px;
    padding: 60px 0;
    margin: 0 auto;

    .p-section-contact {
      width: 100%;
      margin: 0 auto;
      text-align: center;

      .item-wrap {
        margin: 0 0 20px;

        &.name-wrap {
          display: flex;

          div {
            width: 50%;
            margin: 0;
          }
        }

        .label-area {
          display: flex;
          flex-wrap: wrap;
          padding: 0 0 2px;

          .txt-color-alert {
            margin: 0 5px;

            &.require {
              opacity: 0.6;
            }

            &.err {
              margin-left: auto;
              word-break: keep-all;
            }
          }
        }

        .data-area {
          width: 100%;
          text-align: left;
          margin: 10px 0;
          padding: 0 0 10px;
          border-bottom: solid 2px #dcdee1;
          white-space: pre;
        }
      }

      .p-btn-wrap {
        display: flex;
        align-items: center;

        .p-btn-edit {
          margin-right: 15%;
        }

        .p-btn-contact {
          margin-right: auto;
        }
      }
    }
  }

  ${BreakPoints.large} {
    .p-section-inner {
      .p-section-contact {
      }
    }
  }
`

type IProps = {
  /** 送信中フラグ */
  isSending: boolean
  /** 問い合わせ情報 */
  contact: IContactSupport
  /** 戻るボタンクリック時コールバック */
  onClickBack: () => void
  /** 確認ボタンクリック時コールバック */
  onClickSend: () => void
}
/**
 * 利用ユーザ用:お問い合わせ-入力確認画面
 * @constructor
 */
const OfficialSupport02 = ({
  isSending,
  contact,
  onClickBack,
  onClickSend,
}: IProps) => {
  const getSupportName = useKindName(SupportKindList)
  const getDeviceName = useKindName(DeviceKindList)

  return (
    <>
      <OfficialLowHeader
        label="ご利用サポート"
        description="入力内容をご確認後、送信ボタンを押してください"
      />
      <Wrapper>
        <div className="p-section-inner">
          <ContactFlow className="now-02" />
          <div className="p-section-contact">
            {/** 会社名 */}
            <div className="item-wrap">
              <p className="label-area">
                <span className="txt-bold">会社名</span>
              </p>
              <p className="data-area">{contact.company}</p>
            </div>
            {/** 会社名 */}
            <div className="item-wrap">
              <p className="label-area">
                <span className="txt-bold">企業ID</span>
              </p>
              <p className="data-area">{contact.companyId}</p>
            </div>
            {/** 氏名 */}
            <div className="item-wrap">
              <p className="label-area">
                <span className="txt-bold">ご担当者名</span>
              </p>
              <p className="data-area">{contact.name}</p>
            </div>
            {/** メールアドレス */}
            <div className="item-wrap">
              <p className="label-area">
                <span className="txt-bold">登録メールアドレス</span>
              </p>
              <p className="data-area">{contact.email}</p>
            </div>
            {/** お問い合わせ種別 */}
            <div className="item-wrap">
              <p className="label-area">
                <span className="txt-bold">お問い合わせ種別</span>
              </p>
              <p className="data-area">{getSupportName(contact.supportKind)}</p>
            </div>
            {/** お問い合わせの詳細 */}
            <div className="item-wrap">
              <p className="label-area">
                <span className="txt-bold">お問い合わせの詳細</span>
              </p>
              <div className="data-area">{contact.body}</div>
            </div>
            {/** 利用環境 */}
            <div className="item-wrap">
              <p className="label-area">
                <span className="txt-bold">利用環境</span>
              </p>
              <p className="data-area">
                {getDeviceName(contact.deviceKind) ?? 'ー'}
              </p>
            </div>
            {/** エラーメッセージ */}
            <div className="item-wrap">
              <p className="label-area">
                <span className="txt-bold">エラーメッセージ</span>
              </p>
              <div className="data-area">{contact.error}</div>
            </div>
            <div className="p-btn-wrap">
              {/** 修正 */}
              <OfficialCommonBtn
                size="small"
                level="weak"
                className="p-btn-edit"
                onClick={onClickBack}
              >
                修正する
              </OfficialCommonBtn>
              {/** 送信 */}
              <OfficialCommonBtn
                size="large"
                level="normal"
                className="p-btn-contact"
                onClick={onClickSend}
                disabled={isSending}
              >
                この内容で送信する
              </OfficialCommonBtn>
            </div>
          </div>
        </div>
      </Wrapper>
    </>
  )
}
export default OfficialSupport02
