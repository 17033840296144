/**
 * 問い合わせ種別＠サポート
 */
export const SupportKind = {
  NONE: '',
  /** 導入方法のご相談 */
  OPINION: 'introduction-method',
  /** 勤怠データの引継ぎ登録に関するご相談 */
  INHERIT: 'inherit',
  /** 月の開始日変更に関するご相談 */
  START_DATE: 'start-date',
  /** ご意見・ご要望・不具合の報告 */
  HOW_TO_USE: 'detail',
  /** その他のお問い合わせ */
  OTHER: 'other',
} as const
export type SupportKindType = typeof SupportKind[keyof typeof SupportKind]

export const SupportKindList: {
  name: string
  kind: SupportKindType
}[] = [
  { name: '利用方法に関するお問い合わせ', kind: SupportKind.OPINION },
  { name: '勤怠データの引継ぎ登録に関するご相談', kind: SupportKind.INHERIT },
  { name: '月の開始日変更に関するご相談', kind: SupportKind.START_DATE },
  { name: 'ご意見・ご要望・不具合の報告', kind: SupportKind.HOW_TO_USE },
  { name: 'その他のお問い合わせ', kind: SupportKind.OTHER },
]
