import styled from '@emotion/styled'
import React from 'react'
import BreakPoints from '../../styles/breakPoints'
import ContactFlow from '../molecules/contactFlow'
import OfficialCommonH3Set from '../molecules/officialCommonH3Set'
import OfficialLowHeader from './officialLowHeader'

const Wrapper = styled.section`
  width: 100%;

  .p-section-inner {
    display: flex;
    max-width: 1080px;
    padding: 60px 0;
    margin: 0 auto;
    flex-wrap: wrap;
    justify-content: space-between;
  }

  ${BreakPoints.large} {
    .p-section-inner {
      .p-section-download {
      }
    }
  }
`
/**
 * 利用ユーザ:用お問い合わせ-完了
 * @constructor
 */
const OfficialSupport03 = () => (
  <>
    <OfficialLowHeader label="ご利用サポート" description="送信完了" />
    <Wrapper className="thanks">
      <div className="p-section-inner">
        <ContactFlow className="now-03" />
        <OfficialCommonH3Set
          label="お問い合わせありがとうございます"
          description={
            'お問い合わせいただいた内容を確認し\n2～3営業日以内に、担当よりメールにて返信させていただきます。'
          }
        />
      </div>
    </Wrapper>
  </>
)

export default OfficialSupport03
