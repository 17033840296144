import { useEffect } from 'react'
import { API } from 'aws-amplify'

const awsmobile = require('../aws-exports')

type IExecApi<Body, Response> = (
  path: string,
  body: Body,
  success: (response: Response) => void,
  error?: () => void
) => void

type IReturn<Body, Response> = { execApi: IExecApi<Body, Response> }
const useApi = <Body, Response>(method = 'post'): IReturn<Body, Response> => {
  useEffect(() => {
    API.configure(awsmobile.default)
  }, [])

  /** APIを実行 */
  const execApi: IExecApi<Body, Response> = async (
    path,
    body,
    success,
    error
  ) => {
    const APIName = 'beaverOfficial'
    const params = {
      body,
    }
    try {
      switch (method) {
        case'get':
          success(await API.get(APIName, path, params))
          break
        default:
          success(await API.post(APIName, path, params))
          break
      }
    } catch (e) {
      error && error()
    }
  }
  return { execApi }
}

export default useApi
